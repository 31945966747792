import React, { Component } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import Cookies from 'js-cookie';

const myToken = 'ca500065-b2ee-4076-a429-e10d25965805'

export const siteMetaDataQuery = graphql`
query ApiUrlQuery {
  site {
    siteMetadata {
      apiUrl
    }
  }
}
`

class CookList extends Component {
  state = {
    loading: false,
    error: false,
    cook: false,
    auth_token : Cookies.get('auth_token')
  }
  componentDidMount() {
    this.fetchCooksList()
  }
  render() {
    const auth_token = this.state.auth_token
    if(auth_token !== 'b5488b1e-02db-433f-8ca2-7a186da42ae9'){
      return (
        'Unauthorized'
      )
    }
    const cook = this.state.cook
    return (
      <div style={{ textAlign: "center", width: "600px", margin: "50px auto" }}>
        <nav><a href="/cook">Add a Cook</a></nav>
        <div id="messages"></div>
        <div>
          {this.state.loading ? (
            <p>Loading...</p>
          ) : cook ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th colSpan="2">Status</th>
                </tr>
              </thead>
              <tbody>
            {cook.map((cook) =>
            <tr key={cook.id}>
              <td><a href={`/cook?cook_id=`+cook.id}>{cook.id}</a></td>
              <td>{cook.name}</td>
              <td>{cook.status ? "Open" : "Closed"}</td>
              <td><button rel={cook.id} onClick={(e) => {if (window.confirm('Are you sure you want to delete this cook?')) this.deleteCook(e) }}>Delete</button></td>
            </tr>
            )}</tbody>
            </table>
          ) : (
            <p>Oh noes, error fetching cook :(</p>
          )}
        </div>
      </div>
    )
  }
  deleteCook = (e) => {
    const id = e.target.getAttribute('rel');
    const myData = { 'token': myToken, 'cook_id': id };
    const options = {
      method: 'DELETE',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: myData,
      url: this.props.data.site.siteMetadata.apiUrl+`/cook`,
    };
    axios(options)
    .then(
      ()=>{
      this.fetchCooksList()}
    )
    .catch(error => {
      this.setState({ loading: false, error })
    });
  }
  
  fetchCooksList = () => {
    this.setState({ loading: true })
    axios
      .get(this.props.data.site.siteMetadata.apiUrl+`/list?token=`+myToken)
      .then(
        
        cook => {
        this.setState({
          loading: false,
          cook: cook.data
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }
}
export default CookList
